// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// third party
import Rails from '@rails/ujs';
import '@fortawesome/fontawesome-free/css/all.min.css';

// internal
function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./config/', true, /.js$/));
importAll(require.context('./css/', true, /.scss$/));

require.context('../images', true);

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
window.Stimulus = Application.start();

import {
  RaincoatMDCBannerController,
  RaincoatMDCButtonController,
  RaincoatMDCDataTableController,
  RaincoatMDCDialogController,
  RaincoatMDCFormFieldController,
  RaincoatMDCSelectController,
  RaincoatMDCSwitchController,
  RaincoatMDCTabBarController,
  RaincoatMDCTextFieldController,
  RaincoatMDCTooltipController
} from 'raincoat';
Stimulus.register('mdc--banner', RaincoatMDCBannerController);
Stimulus.register('mdc--button', RaincoatMDCButtonController);
Stimulus.register('mdc--data-table', RaincoatMDCDataTableController);
Stimulus.register('mdc--dialog', RaincoatMDCDialogController);
Stimulus.register('mdc--form-field', RaincoatMDCFormFieldController);
Stimulus.register('mdc--select', RaincoatMDCSelectController);
Stimulus.register('mdc--switch', RaincoatMDCSwitchController);
Stimulus.register('mdc--tab-bar', RaincoatMDCTabBarController);
Stimulus.register('mdc--text-field', RaincoatMDCTextFieldController);
Stimulus.register('mdc--tooltip', RaincoatMDCTooltipController);

const context = require.context('./controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
Rails.start();
