import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'genderIdentityOther',
    'raceOther',
    'sexualOrientationOther',
    'telephoneField',
    'positionOther'
  ];

  connect() {
    this.telephoneFieldTarget
      .getElementsByTagName('input')[0]
      .setAttribute('pattern', '(?[0-9]{3})?-?[0-9]{3}-?[0-9]{4}');
    this.telephoneFieldTarget
      .getElementsByTagName('input')[0]
      .setAttribute('title', 'Please enter a valid phone number.');
  }

  toggleGenderField() {
    if (this.genderIdentityOtherTarget.style.display === 'none') {
      this.genderIdentityOtherTarget.style.display = 'block';
    } else {
      this.genderIdentityOtherTarget.style.display = 'none';
    }
  }

  toggleOrientationField() {
    if (this.sexualOrientationOtherTarget.style.display === 'none') {
      this.sexualOrientationOtherTarget.style.display = 'block';
    } else {
      this.sexualOrientationOtherTarget.style.display = 'none';
    }
  }

  toggleRaceField() {
    if (this.raceOtherTarget.style.display === 'none') {
      this.raceOtherTarget.style.display = 'block';
    } else {
      this.raceOtherTarget.style.display = 'none';
    }
  }

  togglePositionField() {
    if (this.positionOtherTarget.style.display === 'none') {
      this.positionOtherTarget.style.display = 'block';
    } else {
      this.positionOtherTarget.style.display = 'none';
    }
  }
}
